import React, {FC} from "react";
import Slider from "react-slick";
import {Col, Row, Typography} from "antd";
import carousel1 from "../assets/landing_carousel_1.svg";
import carousel2 from "../assets/exciting.svg";
import carousel3 from "../assets/landing_carousel_2.svg";
import carousel4 from "../assets/landing_carousel_4.svg";


interface InfoCarouselProps {
    location: any
}

const InfoCarousel: FC<InfoCarouselProps> = (props) => {


    return (
        <div style={{width: '100%', height: '100%'}}>
            <Slider dots={true} className={'carousel'} autoplay={true} autoplaySpeed={15000} slidesToScroll={1} slidesToShow={1}>
                <div>
                    <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={carousel1} alt="carousel-1" style={{width: '75%'}}/>
                        </Col>
                    </Row>
                    <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px', justifyContent: 'center'}}>
                        <Typography style={{fontSize: '26px', fontWeight: 'bold'}}>Welcome To The Future</Typography>
                    </Row>
                    <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                                Streamline payments and automate loyalty rewards by enabling your favorite merchants (like {props?.location?.location_name}) to anonymously recognize you.
                            </Typography>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={carousel2} alt="carousel-2" style={{width: '75%'}}/>
                        </Col>
                    </Row>
                    <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px', justifyContent: 'center'}}>
                        <Typography style={{fontSize: '26px', fontWeight: 'bold'}}>It's Like Magic</Typography>
                    </Row>
                    <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                                Sign up, add your card, and you're good to go! When making a purchase, just tell the cashier you'd like to pay with YewPay. We'll charge your card on file and you'll earn rewards instantly.
                            </Typography>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={carousel3} alt="carousel-3" style={{width: '75%'}}/>
                        </Col>
                    </Row>
                    <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px', justifyContent: 'center'}}>
                        <Typography style={{fontSize: '26px', fontWeight: 'bold'}}>Private & Secure</Typography>
                    </Row>
                    <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                                Never share your payment information or vocalize your phone number for loyalty ever again! YewPay allows merchants to tender payment and apply loyalty while you remain anonymous.
                            </Typography>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <img src={carousel4} alt="carousel-4" style={{width: '75%'}}/>
                        </Col>
                    </Row>
                    <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px', justifyContent: 'center'}}>
                        <Typography style={{fontSize: '26px', fontWeight: 'bold'}}>Ditch Your Wallet</Typography>
                    </Row>
                    <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden', justifyContent: 'center'}}>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                                Fully manage your payments and rewards from one spot. Easily track your transactions across cards, and choose which charges hit which cards (even after purchase).
                            </Typography>
                        </Col>
                    </Row>
                </div>
            </Slider>
        </div>
    )
}

export default InfoCarousel;