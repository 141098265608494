import React, {type FC, useEffect, useState} from 'react'
import './AuthComponent.css';
import "react-simple-keyboard/build/css/index.css";
import {Button, Col, Row, Typography} from "antd";
import {formatAmount} from "../utils/MiscUtils";
import logo from "../assets/yewpay_logo_positive_primary.svg";
import CustomTipModal from "./CustomTipModal";
import {PaymentsService} from "../services/PaymentsService";


interface ConfirmScreenProps {
    location: any
    payment_intent: any
    onConfirmSuccess: (result: any) => void
}

const ConfirmScreen: FC<ConfirmScreenProps> = (props) => {

    const [tipOptions, setTipOptions] = useState<any>([])
    const [discountsTotal, setDiscountsTotal] = useState<any>(0)
    const [customModalOpen, setCustomModalOpen] = useState<any>(false);
    const [loading, setLoading] = useState<boolean>(false)

    const calculateTipOptions = () => {
        const options: any = []
        const original_amount = props.payment_intent?.metadata?.original_amount
        if (original_amount >= 500) {
            options.push(['15%', Math.floor(original_amount * 0.15)])
            options.push(['20%', Math.floor(original_amount * 0.20)])
            options.push(['25%', Math.floor(original_amount * 0.25)])
        } else {
            options.push([null, 100])
            options.push([null, 200])
            options.push([null, 300])
        }
        setTipOptions(options)
    }

    const calculateDiscountsTotal = () => {
        let discounts = 0
        discounts += +props.payment_intent?.metadata?.promo_discount || 0
        discounts += +props.payment_intent?.metadata?.loyalty_discount || 0
        discounts += +props.payment_intent?.metadata?.credit_discount || 0
        setDiscountsTotal(discounts)
    }

    useEffect(() => {
        calculateTipOptions()
        calculateDiscountsTotal()
    }, [props.payment_intent])

    const openCustomTipModal = () => {
        setCustomModalOpen(true)
    }

    const onCustomModalClose = () => {
        setCustomModalOpen(false)
    }

    const confirmWithTip = (tip: number) => {
        setLoading(true)
        const payload = {'payment_intent_id': props.payment_intent?.id, 'tip': tip}
        PaymentsService.confirmPayment(JSON.stringify(payload), setLoading).subscribe((data: any) => {
            props.onConfirmSuccess(data);
        }, (error: any) => {
            setLoading(false)
            console.error(`Error confirming payment with tip! ${error}`)
        });
    }

    return (
        <div style={{height: '100%', maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column', padding: '30px', boxSizing: 'border-box'}}>
            <Row style={{width: '100%'}}>
               <Col span={24} style={{marginTop: '30px'}}>
                   <Typography style={{fontSize: '42px', fontWeight: 'bold'}}>{formatAmount(props.payment_intent?.amount)}</Typography>
               </Col>
                {discountsTotal > 0 &&
                    <Col span={24} style={{marginTop: '30px'}}>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <Typography style={{fontSize: '20px'}}>{formatAmount(props.payment_intent?.metadata?.original_amount)}</Typography>
                                <Typography style={{fontSize: '20px'}}>Order Total</Typography>
                            </div>
                            <div style={{marginLeft: '40px', marginRight: '40px'}}>
                                <Typography style={{fontSize: '32px'}}>-</Typography>
                            </div>
                            <div>
                                <Typography style={{fontSize: '20px'}}>{formatAmount(discountsTotal)}</Typography>
                                <Typography style={{fontSize: '20px'}}>Discounts</Typography>
                            </div>
                        </Row>
                    </Col>
                }
            </Row>
            <Row style={{flex: 1, height: '100%', marginTop: '20px', alignItems: 'center'}}>
                <Col span={24}>
                    <Row>
                        <Col span={24} style={{marginBottom: '20px'}}>
                            <Typography style={{fontSize: '20px'}}>Add a tip?</Typography>
                        </Col>
                        {tipOptions?.length > 0 &&
                            <Col span={24} style={{display: 'flex', justifyContent: 'space-between'}}>
                                {tipOptions.map((option: any, index: number) => (
                                    <Button key={index} disabled={loading} loading={loading} type="primary" onClick={() => confirmWithTip(option[1])} style={{height: '150px',
                                        flex: `0 1 calc(100% / ${tipOptions.length} - 10px + 10px / ${tipOptions.length})`, // Adjust the width to account for the margins
                                    }}>
                                        {tipOptions[index][0] ? (
                                            <div>
                                                <Typography style={{fontSize: '28px', fontWeight: '550', color: '#fff'}}>{option[0]}</Typography>
                                                <Typography style={{fontSize: '18px', fontWeight: '550', color: '#fff'}}>{formatAmount(option[1])}</Typography>
                                            </div>
                                        ) : (
                                            <div>
                                                <Typography style={{fontSize: '28px', fontWeight: '550', color: '#fff'}}>{formatAmount(option[1], true)}</Typography>
                                            </div>
                                        )
                                        }
                                    </Button>
                                ))}
                            </Col>
                        }
                        <Col span={24} style={{marginTop: '20px'}}>
                            <Button disabled={loading} loading={loading} block type="primary" onClick={() => openCustomTipModal()} style={{height: '80px', width: '100%', fontSize: '18px', fontWeight: '550', color: '#fff'}}>
                                Custom
                            </Button>
                        </Col>
                        <Col span={24} style={{marginTop: '20px'}}>
                            <Button disabled={loading} loading={loading} block type="default" onClick={() => confirmWithTip(0)} style={{height: '80px', width: '100%', fontSize: '18px', fontWeight: '550', color: '#7ac968'}}>
                                No Tip
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: '30px'}}>
                <img src={logo} alt="Logo" style={{height: '30px', zIndex: 2, marginTop: '10px'}}/>
            </Row>
            <CustomTipModal open={customModalOpen} onClose={onCustomModalClose} addTip={(tip: number) => confirmWithTip(tip)} />
        </div>
    )
}

export default ConfirmScreen
