
export function titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
}

export function titleCaseSentence(sentence: string) {
    if (!sentence) return sentence;
    return sentence
        .split('_')
        .map(titleCaseWord)
        .join(' ');
}

export function roundHalf(num: number): number {
    return Math.round(num * 2) / 2;
}

export function formatAmount(amountString: any, short=false) {
    if (!amountString) {
        return '$0.00'
    }
    const amountInCents = parseInt(amountString, 10);
    const amountInDollars = amountInCents / 100;
    return `$${amountInDollars.toFixed(short ? 0 : 2)}`;
}