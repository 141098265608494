import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Modal, Typography} from 'antd'
import CurrencyInput from './CurrencyInput';
import KeyboardReact from 'react-simple-keyboard';
import styles from './AuthComponent.module.css';
import {formatAmount} from "../utils/MiscUtils";

type CustomTipModalProps = {
    open: boolean
    onClose: () => void
    addTip: (tip: number) => void
}

const CustomTipModal: React.FC<CustomTipModalProps> = (props) => {
    const [amount, setAmount] = useState<any>(0)
    const amountRef = useRef(amount);
    const currencyInputRef = useRef<any>(null);
    const keyboardChargeRef = useRef<any>();

    const addTip = () => {
        props.addTip(amountRef.current)
        handleClose()
    }

    const handleModalCancel = () => {
        console.log(`Handling modal cancel`)
        handleClose()
    }

    const handleClose = () => {
        console.log(`Closing LoyaltyModal`)
        setAmount(0)
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.clearInput('amount');
            currencyInputRef.current?.clearInput();
        }
        props.onClose()
    }


    const keyboardChargeLayout = {
        'default': [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00',
            '{enter}'
        ]
    }

    const keyboardChargeDisplay = {
        '{bksp}': '<',
        '{enter}': amount > 0 ? `Add ${formatAmount(amount)} Tip` : `No Tip`
    }

    const onKeyReleasedLoyalty = useCallback((button: any, e: MouseEvent | undefined) => {
        console.log(button)
        if (button == '{enter}') {
            e?.stopImmediatePropagation()
            addTip()
        } else if (button == '{bksp}') {
            keyboardChargeRef.current.setCaretPosition(amountRef.current.length)
        }
    }, [keyboardChargeRef.current, amountRef])

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;
        console.log(`onChangeInput ${inputVal}`)
        setAmount(+inputVal)

        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.setInput(inputVal);
        }
    };

    const onInputValueChanged = (inputName: string, inputValue: any) => {
        console.log(`onInputValueChanged ${inputValue}`)
        keyboardChargeRef?.current?.setInput(inputValue)
    }

    const onChangeAll = (inputs: any) => {
        console.log(`onChangeAll ${JSON.stringify(inputs)}`)
        setAmount(inputs['amount'])
    };

    useEffect(() => {
        console.log(`Amount changed! ${amount}`)
        amountRef.current = amount;
    }, [amount])


    return (
        <Modal
            centered
            open={props.open}
            onCancel={handleModalCancel}
            width={'100%'}
            footer={[]}
        >
            <div style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                marginTop: '10px'
            }}>
                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                    Tip Amount
                </Typography.Text>
                <CurrencyInput
                    ref={currencyInputRef}
                    inputName={'amount'}
                    setFocusedInput={() => { }}
                    rawValue={amount}
                    disabled={false}
                    onChange={onChangeInput}
                    inputValueChanged={onInputValueChanged}
                />
                <KeyboardReact
                    keyboardRef={r => (keyboardChargeRef.current = r)}
                    layout={keyboardChargeLayout} display={keyboardChargeDisplay}
                    buttonTheme={keyboardButtonTheme}
                    onKeyReleased={onKeyReleasedLoyalty}
                    inputName={'amount'}
                    onChangeAll={onChangeAll}
                />
            </div>
        </Modal>
    );
};

export default CustomTipModal;
