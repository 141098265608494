import React, {FC} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import './AuthComponent.css';

interface PhoneNumberInputProps {
    phone: string
    showHeader: boolean
    padding?: number
}
const PhoneNumberInput: FC<PhoneNumberInputProps> = (props) => {

    const handlePhoneChange = (value: any) => {
        console.log(`Phone set to +${value}`)
    };

    const padding = props.padding === undefined ? 20 : props.padding;

    const getPhoneValue = (phone_input: any) => {
        if (phone_input?.length > 0) {
            return '' + phone_input
        }
        return ''
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '16px', paddingBottom: '16px', width: '100%'}}>
            {props.showHeader &&
                <h2 style={{ fontWeight: '600' }}>Register Phone</h2>

            }
            <PhoneInput
                onlyCountries={['us']}
                country={'us'}
                disableCountryCode={true}
                value={getPhoneValue(props.phone)}
                onChange={handlePhoneChange}
                specialLabel=''
                inputClass="phone-number-input"
                dropdownClass="phone-dropdown"
                countryCodeEditable={false}
                copyNumbersOnly={true}
                disableDropdown={true}
            />
        </div>
    );
};



export { PhoneNumberInput };
