import React, {type FC, useEffect, useRef, useState} from 'react'
import {Button, Col, Progress, Row, Steps, Typography} from 'antd'
import logo from '../assets/yewpay_logo_positive_primary.svg';
import './AuthComponent.css';
import {KeyboardReact} from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InfoCarousel from "./InfoCarousel";
import {PhoneOutlined, SmileOutlined} from "@ant-design/icons";
import {PhoneNumberInput} from "./PhoneNumberInput";
import styles from './AuthComponent.module.css';
import {UserService} from "../services/UserService";

const RESET_WAIT = 15000


interface BaseScreenProps {
    location: any
}

const BaseScreen: FC<BaseScreenProps> = (props) => {
    const [step, setStep] = useState<any>(0);
    const [registrationStep, setRegistrationStep] = useState<any>(0);
    const [phone, setPhone] = useState<any>('');
    const keyboardRef = useRef<any>();
    const [focusedInput, setFocusedInput] = useState('phone');
    const [inputs, setInputs] = useState<any>({});
    const resetTimeoutRef = useRef<any>(null);
    const resetProgressIntervalRef = useRef<any>(null);
    const [timerProgress, setTimerProgress] = useState(100);
    const [loading, setLoading] = useState<any>(false);

    const keyboardLayout = {
        'default': [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00',
            '{enter}'
        ]
    }

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const keyboardButtonThemeDisabled = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action_disabled,
            buttons: '{enter}'
        }
    ]


    useEffect(() => {
        console.log(`Inputs updated: ${JSON.stringify(inputs)}`)
    }, [inputs])

    useEffect(() => {
        if (step == 1) {
            restartResetTimeout()
        }

        document.addEventListener('click', restartResetTimeout);

        return () => {
            if (resetTimeoutRef.current) clearTimeout(resetTimeoutRef.current)
            document.removeEventListener('click', restartResetTimeout);
        }
    }, [step])

    const restartResetTimeout = () => {
        if (resetTimeoutRef.current) clearTimeout(resetTimeoutRef.current)
        if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current)
        setTimerProgress(100);
        resetTimeoutRef.current = setTimeout(() => {
            resetState();
        }, RESET_WAIT)

        // Update progress every second
        const startTime = Date.now();
        resetProgressIntervalRef.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const progress = 100 - (elapsedTime / RESET_WAIT) * 100;
            setTimerProgress(progress);
            if (progress <= 0) {
                if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current);
            }
        }, 1000); // Update every second
        return () => {
            if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current);
        } // Clear interval on reset or component unmount
    }

    const submitPhoneNumber = () => {
        // setLoading(true);
        // setTimeout(() => {
        //   setLoading(false);
        //   setRegistrationStep(1);
        // }, 1000)
        if (phone && phone.length >= 10) {
            setLoading(true);
            UserService.enrollPhoneVerified(JSON.stringify({phone: `+1${phone}`}), setLoading).subscribe((success: any) => {
                setRegistrationStep(1);
            }, (error: any) => {
                console.log(`Error hit enrolling phone: ${error}`)
                setLoading(false);
                resetState();
            });
        }
    }

    const onKeyReleased = (button: any, e: any) => {
        const caretPos = keyboardRef.current.getCaretPosition()
        console.log(`Button pressed: ${button}, value: ${getInputValue("phone")}, cursor: ${caretPos}`)
        if (button == '{enter}') {
            e.stopPropagation();
            console.log(`submitting number: ${phone}`)
            submitPhoneNumber()
        } else if (button == '{bksp}') {
            keyboardRef.current.setCaretPosition(getInputValue('phone').length)
            setPhone(getInputValue('phone'))
        } else {
            setPhone(getInputValue('phone'))
        }
    };

    const getInputValue = (inputName: string) => {
        return inputs[inputName] || "";
    };

    const onChangeAll = (inputs: any) => {
        console.log(`onChangeAll`)
        inputs['phone'] = inputs['phone'].slice(0, 10)
        setInputs({...inputs});
    };

    const resetState = () => {
        if (keyboardRef?.current) {
            console.log(`Resetting state`)
            keyboardRef.current.clearInput('phone');
            setPhone('');
            setStep(0);
            setRegistrationStep(0);
            if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current);
            if (resetTimeoutRef.current) clearTimeout(resetTimeoutRef.current);
        }
    }

    function formatPhoneNumber(input: string): string {
        // Ensure the input is exactly 10 characters
        if (input.length !== 10) {
            throw new Error("Input must be exactly 10 characters long.");
        }

        // Extract parts of the phone number
        const areaCode = input.slice(0, 3);
        const firstPart = input.slice(3, 6);
        const secondPart = input.slice(6, 10);

        // Format and return the phone number
        return `(${areaCode}) ${firstPart}-${secondPart}`;
    }

    const renderContent = () => {
        switch (step) {
            case 0: // Home
                return (
                    <>
                        <Row style={{flex: '0 0 50%', maxHeight: '50%', width: '100%', overflow: 'hidden', paddingBottom: '16px'}}>
                            <InfoCarousel location={location}/>
                        </Row>
                        <Row style={{flex: '0 0 25%', maxHeight: '25%', width: '100%', overflow: 'auto', display: 'flex', justifyContent: 'center'}}>
                            <Col span={18} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                <div style={{width: '100%'}}>
                                    <Button type="primary" onClick={() => setStep(1)} block style={{fontSize: '26px', fontWeight: '600', height: '80px'}}>
                                        GET $5 OFF YOUR ORDER
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            case 1: // Verify Phone
                return (
                    <>
                        <div style={{position: 'absolute', top: 10, right: 10}}>
                            <Progress type="circle" percent={Math.round(timerProgress)} size={24} strokeColor={'#7ac968'} showInfo={false}/>
                        </div>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <Col span={18} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                <Steps current={registrationStep} responsive={false} style={{width: '100%'}} className={'step_class'}>
                                    <Steps.Step title="Verify Phone" icon={<PhoneOutlined/>} status={registrationStep == 0 ? 'process' : 'finish'}/>
                                    <Steps.Step title="Create Face ID" icon={<SmileOutlined />} status={registrationStep > 0 ? 'finish' : 'wait'}/>
                                </Steps>
                            </Col>
                        </Row>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px', height: '100%'}}>
                            <Col span={22} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'start', height: '100%'}}>
                                {renderRegistrationContent()}
                            </Col>
                        </Row>
                    </>

                );
            default:
                return null;
        }
    };

    const renderRegistrationContent = () => {
        switch (registrationStep) {
            case 0:
                return (
                    <>
                        <Row>
                            <Typography style={{fontSize: '22px', fontWeight: 'bold'}}>Create your YewPay Account</Typography>
                        </Row>
                        <Row>
                            <Typography style={{fontSize: '16px', textAlign: 'start', marginTop: '16px'}}>
                                Enter your phone number to get a verified link sent to you that you can use to complete registration on your phone.
                            </Typography>
                        </Row>
                        <Row style={{marginTop: '16px', width: '100%'}}>
                            <PhoneNumberInput phone={phone} showHeader={false} />
                        </Row>
                        <Row style={{width: '100%', marginTop: '16px'}}>
                            <KeyboardReact
                                keyboardRef={r => (keyboardRef.current = r)}
                                layout={keyboardLayout} display={{
                                '{bksp}': '<',
                                '{enter}': !loading ? `SUBMIT` : `SUBMITTING...`
                            }}
                                buttonTheme={phone?.length >= 10 && !loading ? keyboardButtonTheme : keyboardButtonThemeDisabled}
                                onKeyReleased={(button, e) => onKeyReleased(button, e)}
                                inputName={focusedInput}
                                onChangeAll={onChangeAll}
                                maxLength={10}
                            />
                        </Row>
                    </>
                );
            case 1:
                return (
                    <div style={{height: '100%', paddingBottom: '40px'}}>
                        <Row style={{flex: '0 0 40%', maxHeight: '40%', height: '40%'}}>
                            <Col span={24}>
                                <Row>
                                    <Typography style={{fontSize: '22px', fontWeight: 'bold'}}>Verified Link Sent!</Typography>
                                </Row>
                                <Row>
                                    <Typography style={{fontSize: '16px', textAlign: 'start', marginTop: '16px'}}>
                                        Click the verified link sent to you at <span style={{fontWeight: '600'}}>{formatPhoneNumber(phone)}</span> to finish registering for YewPay on your phone.
                                    </Typography>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{width: '100%', flex: '0 0 60%', maxHeight: '60%', height: '60%'}}>
                            <Col span={24} style={{display: 'flex', justifyContent: 'end', flexDirection: 'column', width: '100%'}}>
                                <Button type="primary" onClick={() => resetState()} block style={{fontSize: '26px', fontWeight: '600', height: '80px', width: '100%', marginTop: '40px'}}>
                                    DONE
                                </Button>
                            </Col>
                        </Row>

                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <div style={{height: '100%', maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Row style={{flex: '0 0 20%', maxHeight: '20%', overflow: 'auto', alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '26px'}}>
                <Typography style={{fontSize: '32px', fontWeight: 'bold'}}>{props?.location?.location_name} Rewards</Typography>
                <img src={logo} alt="Logo" style={{height: '40px', zIndex: 2, marginTop: '10px'}}/>
            </Row>
            {renderContent()}
        </div>
    )
}

export default BaseScreen
