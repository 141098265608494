import React, {type FC, useEffect, useRef, useState} from 'react'
import './AuthComponent.css';
import "react-simple-keyboard/build/css/index.css";
import {LocationService} from "../services/LocationService";
import ReconnectingWebSocket from 'reconnecting-websocket';
import {FlowSteps} from "../FlowSteps";
import BaseScreen from "./BaseScreen";
import PaymentResultScreen from "./PaymentResultScreen";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import ConfirmScreen from "./ConfirmScreen";


interface AuthComponentProps {
  onError: (description: string, title?: string) => void
  locations: any[];
}

const AuthComponent: FC<AuthComponentProps> = (props) => {
  const [location, setLocation] = useState<any>(null);
  const cwsRef = useRef<ReconnectingWebSocket | null>(null);
  const lastMessageTimeCWSRef = useRef<Date>(new Date());
  const [connectionsOk, setConnectionsOk] = useState(false);
  const [flowStep, setFlowStep] = useState<any>(FlowSteps.BASE)
  const eventPayloadRef = useRef<any>(null);
  const lastEventRef = useRef<any>(null);

  const openCwsConnection = () => {
    const cws = new ReconnectingWebSocket(`wss://4dx8ylb8c4.execute-api.us-west-2.amazonaws.com/production?location_code=${location.location_code}&product=FACE`);
    cwsRef.current = cws;

    cws.addEventListener('message', (message: any) => {
      const data = JSON.parse(message.data);
      process_customer_message(data)
    });

    // Update the connection status when the websocket is opened or closed
    cws.addEventListener('open', () => setConnectionsOk(cwsRef.current !== null));
    cws.addEventListener('close', () => setConnectionsOk(cwsRef.current !== null));
    cws.addEventListener('error', (error) => {
      // Handle error here
      console.log('Customer Facing WebSocket error:', error);
      setConnectionsOk(false);
    });
  };

  const closeCwsConnection = () => {
    if (cwsRef.current) {
      cwsRef.current.close();
      cwsRef.current = null;
    }
  };

  const process_customer_message = async (data: any) => {
    if (data?.keep_alive) {
      lastMessageTimeCWSRef.current = new Date();
      return
    }
    console.log(`Received customer message: ${JSON.stringify(data)}`)
    lastMessageTimeCWSRef.current = new Date();
    const eventType = data?.event_type
    lastEventRef.current = data
    eventPayloadRef.current = data?.payload
    if (eventType == FlowSteps.USER_RECOGNIZED) {
      setFlowStep(FlowSteps.USER_RECOGNIZED)
    } else if (eventType == FlowSteps.PAYMENT_CONFIRM_REQUEST) {
      setFlowStep(FlowSteps.PAYMENT_CONFIRM_REQUEST)
    } else if (eventType == FlowSteps.PAYMENT_SUCCESS) {
      setFlowStep(FlowSteps.PAYMENT_SUCCESS)
    } else if (eventType == FlowSteps.PAYMENT_FAIL) {
      setFlowStep(FlowSteps.PAYMENT_FAIL)
    }
  }

  function refresh() {
    closeCwsConnection();
    openCwsConnection();

    // create an interval that checks the last message time and refreshes connection if needed
    const checkInterval = setInterval(() => {
      const now = new Date();
      // Check the payments WS
      const diffInMinutesCWS = (now.getTime() - lastMessageTimeCWSRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesCWS >= 1) { // adjust time as needed
        if (cwsRef.current) {
          cwsRef.current.reconnect(1000, 'Manual reconnect');
          lastMessageTimeCWSRef.current = new Date();
        }
      }

      setConnectionsOk(cwsRef.current != null && cwsRef.current.readyState === WebSocket.OPEN);
    }, 1000 * 5); // check every 5 seconds

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeCwsConnection()
      setConnectionsOk(false);
    };
  }


  useEffect(() => {
    if (props?.locations?.length > 0) {
      LocationService.getLocation(props.locations[0], null, null).subscribe((data: any) => {
        setLocation(data);
      }, (error: any) => {
        console.log(`Unable to pull location detail! ${error}`)
      });

    }
  }, [props.locations])


  useEffect(() => {
    if (location) {
      // process_customer_message(mock_event)
      return refresh();
    }
  }, [location])

  useEffect(() => {
    console.log(`FlowStep changed: ${flowStep}`)
  }, [flowStep])

  const confirmationResult = (result: any) => {
    console.log(`Received confirmation result: ${JSON.stringify(result)}`)
    setFlowStep(FlowSteps.PAYMENT_RESULT_PENDING)
    eventPayloadRef.current = result
    if (result?.last_payment_error || result?.status == 'succeeded') {
      setFlowStep(result?.status == 'succeeded' ? FlowSteps.PAYMENT_SUCCESS : FlowSteps.PAYMENT_FAIL)
    }
  }

  const onPaymentCompleted = () => {
    console.log(`Payment completed, going back to base`)
    setFlowStep(FlowSteps.BASE)
    eventPayloadRef.current = null
  }


  return (
      <>
        {flowStep == FlowSteps.BASE &&
            <BaseScreen location={location} />
        }
        {/*{flowStep == FlowSteps.USER_RECOGNIZED &&*/}
        {/*    <UserScreen location={location} user={eventPayloadRef.current} />*/}
        {/*}*/}
        {flowStep == FlowSteps.PAYMENT_CONFIRM_REQUEST &&
            <ConfirmScreen location={location} payment_intent={eventPayloadRef.current} onConfirmSuccess={(result: any) => confirmationResult(result)}/>
        }
        {flowStep == FlowSteps.PAYMENT_RESULT_PENDING &&
            <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Spin indicator={<LoadingOutlined style={{fontSize: '80px'}}/>}></Spin>
            </div>
        }
        {(flowStep == FlowSteps.PAYMENT_SUCCESS || flowStep == FlowSteps.PAYMENT_FAIL) &&
            <PaymentResultScreen location={location} payment_intent={eventPayloadRef.current} onCompleted={() => onPaymentCompleted()} />
        }
      </>
  )
}

export default AuthComponent
