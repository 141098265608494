import {Component} from 'react'
import {Observable} from 'rxjs'

export class GeneralService extends Component {
  public static readonly USER_ENDPOINT = "https://api.yewfi.com"
  public static readonly PROD_HOST = 'api-user.yewfi.com'
  public static readonly PROD_ENDPOINT = `https://${GeneralService.PROD_HOST}`
  // public static readonly PROD_HOST = '127.0.0.1:5000'
  // public static readonly PROD_ENDPOINT = `http://${GeneralService.PROD_HOST}`
  public static readonly options = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'User-Agent': 'Mozilla/5.0',
      'Content-Type': 'application/json',
      'Accept-Charset': 'UTF-8',
      'X-Device-Id': '',
      'Authorization': '',
    }
  }
  private static async refreshToken() {
    const postOptions: any = structuredClone(GeneralService.options)
    postOptions.method = 'POST'
    postOptions['headers']['Authorization'] = this.formatAuth(localStorage.getItem("refreshToken") || "");
    const fullPath = `${GeneralService.USER_ENDPOINT}/user/refresh/token`
    try {
      const response = await fetch(fullPath, postOptions)
      if (response.status == 401) {
        throw new Error("Invalid Refresh Token") 
      }
      const user = await response.json()
      localStorage.setItem("accessToken", user.access_token)
      localStorage.setItem("refreshToken", user.refresh_token)
      return user.access_token
    } catch (error) {
      console.error(`Error refreshing token`)
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      window.location.reload()
    }
  }
  static async handleResponse(response: any, expectedType: any) {
    if (expectedType === JSON) {
      try {
        return await response.json()
      } catch (e) {
        console.error('Error getting JSON from response, getting TEXT instead')
        return response.text
      }
    } else {
      return response.text
    }
  }
  static fetchHandler(path: string, options: any, accessToken: any, loadingHandler: any, expectedType: any, endpoint: any) {
    return Observable.create((observer: any) => {
      const fullPath = `${endpoint}${path}`
      if (accessToken) {
        options['headers']['Authorization'] = this.formatAuth(accessToken);
      }
      if (loadingHandler != null) loadingHandler(true)
      fetch(fullPath, options)
        .then(async response => {
          if (response.ok) {
            return await this.handleResponse(response, expectedType)
          } else if (response.status == 401) {
            console.log(`Unauthorized, refreshing token`)
            const newToken = await this.refreshToken()
            options['headers']['Authorization'] = this.formatAuth(newToken);
            const newResponse = await fetch(fullPath, options)
            if (newResponse.ok) {
              return await this.handleResponse(newResponse, expectedType)
            } else if (newResponse.status == 401) {
              console.error(`Error refreshing token`)
              localStorage.removeItem("accessToken")
              localStorage.removeItem("refreshToken")
              window.location.reload()
            } else {
              return await Promise.reject(response)
            }
          } else {
            return await Promise.reject(response)
          }
        }) // or text() or blob() etc.
        .then(data => {
          observer.next(data)
          observer.complete()
          if (loadingHandler != null) loadingHandler(false)
        })
        .catch(response => {
          if (loadingHandler != null) loadingHandler(false)
          try {
            //Sometimes it's this way, not sure why
            response.text().then((text: string) => {
              observer.error(text)
            })
          } catch (err) {
            observer.error(response.text)
          }
        })
    })
  }

  public static get(path: string, accessToken: any, loadingHandler = null, expectedType: any = JSON, endpoint: any = GeneralService.PROD_ENDPOINT) {
    const getOptions: any = structuredClone(GeneralService.options)
    return GeneralService.fetchHandler(path, getOptions, accessToken, loadingHandler, expectedType, endpoint)
  }

  public static getAsync(path: string, accessToken: any, loadingHandler = null, expectedType: any = JSON, endpoint: any = GeneralService.PROD_ENDPOINT) {
    const getOptions: any = structuredClone(GeneralService.options)
    return GeneralService.fetchHandler(path, getOptions, accessToken, loadingHandler, expectedType, endpoint)
  }

  public static post(path: string, body: any, accessToken: any, loadingHandler = null, expectedType: any = JSON, endpoint: any = GeneralService.PROD_ENDPOINT, contentTypeOveride: any = null) {
    const postOptions: any = structuredClone(GeneralService.options)
    postOptions.method = 'POST'
    postOptions.body = body
    if (contentTypeOveride) {
      delete postOptions.headers['Content-Type']
    }
    return this.fetchHandler(path, postOptions, accessToken, loadingHandler, expectedType, endpoint)
  }

  public static put(path: string, body: string, accessToken: any, loadingHandler = null, expectedType: any = JSON, endpoint: any = GeneralService.PROD_ENDPOINT) {
    const putOptions: any = structuredClone(GeneralService.options)
    putOptions.method = 'PUT'
    putOptions.body = body
    return this.fetchHandler(path, putOptions, accessToken, loadingHandler, expectedType, endpoint)
  }

  public static delete(path: string, body: string, accessToken: any, loadingHandler = null, expectedType: any = JSON, endpoint: any = GeneralService.PROD_ENDPOINT) {
    const deleteOptions: any = structuredClone(GeneralService.options)
    deleteOptions.method = 'DELETE'
    deleteOptions.body = body
    return this.fetchHandler(path, deleteOptions, accessToken, loadingHandler, expectedType, endpoint)
  }

  private static formatAuth(accessToken: string) {
    return `Bearer ${accessToken}`
  }
}

export default GeneralService
