import React, {useEffect, useState} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthComponent from './auth/AuthComponent'
import {ConfigProvider, notification} from 'antd';
import {useAuth0} from "@auth0/auth0-react";
import jwt from 'jwt-decode';


function App(): any {
    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const domain = 'tryyew.us.auth0.com';
    const [locations, setLocations] = useState<any>([]);


    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://api.yewfi.com/locations`,
                    scope: "read:current_user",
                }
            })
                .then((accessToken) => {
                    console.log(`AccessToken: ${accessToken}`)
                    if (!accessToken) {
                        console.error('No access token returned');
                        return;
                    }

                    try {
                        const token: any = jwt(accessToken);
                        console.log(JSON.stringify(token));
                        const result: string[] = token.permissions.map((permission: string) => {
                            const splitPermission: string[] = permission.split(':');
                            return splitPermission[1];
                        });
                        setLocations(result);
                    } catch (e) {
                        console.error('Error decoding the JWT', e);
                    }
                })
                .catch((err) => {
                    console.error('Error getting access token', err);
                });
        } else if (!isLoading) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

    const [api, contextHolder] = notification.useNotification();
    const errorNotification = (description: string, title: string = 'Error Occurred') => {
        api['error']({
            message: title,
            description: description,
            placement: "topRight",
            duration: 0,

        });
    };

    return (
        <ConfigProvider theme={{ token: { fontFamily: 'Montserrat', colorPrimary: '#7ac968' }}}>
            <div className="App">
                {contextHolder}
                {locations?.length > 0 &&
                    <BrowserRouter>
                        <header className="App-header" style={{ height: '100%', maxHeight: '100%' }}>
                            <Routes>
                                <Route path={'*'} element={<AuthComponent onError={errorNotification} locations={locations}/>} />
                            </Routes>
                        </header>
                    </BrowserRouter>
                }
            </div>
        </ConfigProvider>
    )
}

export default App
