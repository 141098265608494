import React, {type FC, useEffect, useRef, useState} from 'react'
import './AuthComponent.css';
import "react-simple-keyboard/build/css/index.css";
import {Col, Progress, Row, Typography} from "antd";
import {formatAmount} from "../utils/MiscUtils";
import logo from "../assets/yewpay_logo_positive_primary.svg";
import {CheckCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";


interface PaymentSuccessScreenProps {
    location: any
    payment_intent: any
    onCompleted: () => void
}

const RESET_WAIT = 15000

const PaymentResultScreen: FC<PaymentSuccessScreenProps> = (props) => {
    const [discountsTotal, setDiscountsTotal] = useState<any>(0)
    const resetTimeoutRef = useRef<any>(null);
    const resetProgressIntervalRef = useRef<any>(null);
    const [timerProgress, setTimerProgress] = useState(100);
    const [showBorder, setShowBorder] = useState(true)

    const calculateDiscountsTotal = () => {
        let discounts = 0
        discounts += +props.payment_intent?.metadata?.promo_discount || 0
        discounts += +props.payment_intent?.metadata?.loyalty_discount || 0
        discounts += +props.payment_intent?.metadata?.credit_discount || 0
        setDiscountsTotal(discounts)
    }

    useEffect(() => {
        calculateDiscountsTotal()
        restartResetTimeout()
        const timer = setTimeout(() => {
            setShowBorder(false);
        }, 5000);

        return () => {
            if (resetTimeoutRef.current) clearTimeout(resetTimeoutRef.current)
            clearTimeout(timer)
        }
    }, [props.payment_intent])


    const restartResetTimeout = () => {
        if (resetTimeoutRef.current) clearTimeout(resetTimeoutRef.current)
        if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current)
        setTimerProgress(100);
        resetTimeoutRef.current = setTimeout(() => {
            finish();
        }, RESET_WAIT)

        // Update progress every second
        const startTime = Date.now();
        resetProgressIntervalRef.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const progress = 100 - (elapsedTime / RESET_WAIT) * 100;
            setTimerProgress(progress);
            if (progress <= 0) {
                if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current);
            }
        }, 1000); // Update every second
        return () => {
            if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current);
        } // Clear interval on reset or component unmount
    }

    const finish = () => {
        console.log('Finishing!')
        if (resetProgressIntervalRef.current) clearInterval(resetProgressIntervalRef.current);
        if (resetTimeoutRef.current) clearTimeout(resetTimeoutRef.current);
        props.onCompleted()
    }



    return (
        <div className={showBorder ? 'blink-green' : ''} style={{height: '100%', maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column',
            boxSizing: 'border-box', border: showBorder ? `30px solid ${!props.payment_intent?.last_payment_error ? '#7ac968' : 'red'}`: '30px solid transparent', animation: showBorder ? `${!props.payment_intent?.last_payment_error ? 'blink-green' : 'blink-red'} 1s linear infinite` : 'none',
            padding: '30px'}}>
            <div style={{position: 'absolute', top: 10, right: 10}}>
                <Progress type="circle" percent={Math.round(timerProgress)} size={24} strokeColor={'#7ac968'} showInfo={false}/>
            </div>
            <Row style={{width: '100%'}}>
                <Col span={24} style={{marginTop: '30px'}}>
                    <Typography style={{fontSize: '42px', fontWeight: 'bold'}}>{formatAmount(props.payment_intent?.amount)}</Typography>
                </Col>
                {(discountsTotal > 0 || props?.payment_intent?.metadata?.tip > 0) &&
                    <Col span={24} style={{marginTop: '30px'}}>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <Typography style={{fontSize: '20px'}}>{formatAmount(props.payment_intent?.metadata?.original_amount)}</Typography>
                                <Typography style={{fontSize: '20px'}}>Order Total</Typography>
                            </div>
                            {discountsTotal > 0 &&
                                <>
                                    <div style={{marginLeft: '40px', marginRight: '40px'}}>
                                        <Typography style={{fontSize: '32px'}}>-</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{fontSize: '20px'}}>{formatAmount(discountsTotal)}</Typography>
                                        <Typography style={{fontSize: '20px'}}>Discounts</Typography>
                                    </div>
                                </>
                            }
                            {props?.payment_intent?.metadata?.tip > 0 &&
                                <>
                                    <div style={{marginLeft: '40px', marginRight: '40px'}}>
                                        <Typography style={{fontSize: '32px'}}>+</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{fontSize: '20px'}}>{formatAmount(props?.payment_intent?.metadata?.tip)}</Typography>
                                        <Typography style={{fontSize: '20px'}}>Tip</Typography>
                                    </div>
                                </>
                            }
                        </Row>
                    </Col>
                }
            </Row>
            <Row style={{flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                {!props.payment_intent?.last_payment_error &&
                    <Col span={24}>
                        <Typography style={{fontSize: '32px', fontWeight: '600'}}>Transaction Successful</Typography>
                        <CheckCircleOutlined style={{color: '#7ac968', fontSize: '120px', marginTop: '40px'}}/>
                    </Col>
                }
                {props.payment_intent?.last_payment_error &&
                    <Col span={24}>
                        <Typography style={{fontSize: '32px', fontWeight: '600'}}>Transaction Failed</Typography>
                        <ExclamationCircleOutlined style={{color: 'red', fontSize: '120px', marginTop: '40px'}}/>
                        <Typography style={{fontSize: '20px', fontWeight: '600', marginTop: '40px'}}>There was a problem processing this transaction.<br/>Please see the cashier.</Typography>
                    </Col>
                }
            </Row>
            <Row style={{alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: '30px'}}>
                <img src={logo} alt="Logo" style={{height: '30px', zIndex: 2, marginTop: '10px'}}/>
            </Row>
        </div>
    )
}

export default PaymentResultScreen
